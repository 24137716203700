<template>
  <div>
    <v-app>
      <v-app-bar flat clipped-left app dark dense>
        <v-btn
          v-if="$power.check('admin_drawer_watch')"
          icon
          @click="drawer = !drawer"
          ><v-icon large>mdi-menu</v-icon></v-btn
        >
        <p v-else>Нет прав</p>
        <div v-if="$power.check('admin_city_change')" class="city">
          <v-img src="../assets/AyanLogo.png" />

          <v-select
            v-model="city"
            :items="cities"
            item-text="name"
            item-value="id"
            dense
            hide-details
            filled
            solo
            background-color="#D9D9D9"
          >
            <template v-slot:append>
              <v-icon color="black">mdi-menu-down</v-icon>
            </template>
            <template slot="selection" slot-scope="data">
              <span class="city_select">{{ data.item.name }}</span>
            </template>
          </v-select>
        </div>
        <v-spacer></v-spacer>
        <div class="ml-4 mr-5">
          <v-menu
            v-if="USER.userInfo.user"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="100"
            offset-y
            dark
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="blue-grey lighten-4"
                dark
                v-bind="attrs"
                text
                class="text-capitalize"
                v-on="on"
              >
                <v-icon class="mr-3">mdi-account</v-icon>
                {{ USER.userInfo.user.username }}
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      "Роль: " + USER.userInfo.user.role.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-switch
                      v-model="theme"
                      color="primary"
                      @change="changeTheme()"
                    ></v-switch>
                  </v-list-item-action>
                  <v-list-item-title>Темная тема</v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-action>
                    <v-btn icon @click="logout()"
                      ><v-icon>mdi-logout</v-icon></v-btn
                    >
                  </v-list-item-action>
                  <v-list-item-title>Выйти</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </v-app-bar>
      <!-- меню слева -->
      <v-navigation-drawer
        v-touch="{
          left: () => (drawer = false),
          right: () => (drawer = true),
        }"
        :mini-variant.sync="drawer"
        mini-variant-width="20"
        permanent
        app
        clipped
        style="background-color: #5d4bd0"
        class="left-menu"
      >
        <v-btn
          fab
          x-small
          absolute
          color="#5D4BD0"
          class="left-menu_close"
          @click.stop="drawer = !drawer"
        >
          <v-icon color="white" large>{{
            drawer ? "mdi-chevron-right" : "mdi-chevron-left"
          }}</v-icon>
        </v-btn>
        <v-list v-if="!isProvider" class="pa-0 pb-md-0 pb-10">
          <!-- "admin_errors_watch" на проверку этих прав повешано много ненужных для локального админа страниц -->
          <div v-for="(group, key) in routesGroup" :key="key">
            <v-list-group no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="routes">{{
                    key
                  }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-icon slot="appendIcon" color="white">mdi-chevron-down</v-icon>

              <!-- пути в подгруппах -->
              <div
                v-for="item in group"
                :key="item.name"
                class="list-item"
                :class="
                  item.path === $route.path && !drawer ? 'active-div' : ''
                "
              >
                <v-list-item
                  v-if="$power.check(item.meta.power)"
                  :ripple="false"
                  :to="item.path"
                  :class="item.path === $route.path && 'active-route'"
                >
                  <v-list-item-icon>
                    <v-icon
                      :color="item.path === $route.path ? '#5d4bd0' : 'white'"
                      >{{ item.meta.icon }}</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="routes">{{
                      item.name
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-group>
          </div>
          <div
            class="list-item"
            :class="'/how-work' === $route.path && 'active-div'"
          >
            <v-list-item
              :ripple="false"
              to="/how-work"
              :class="'/how-work' === $route.path && 'active-route'"
            >
              <v-list-item-content>
                <v-list-item-title class="routes special"
                  >Как работать?</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>

        <!-- список путей для поставщика -->
        <v-list v-else>
          <v-list-item class="list-item" :ripple="false">
            <v-list-item-content class="pb-0">
              <v-list-item-title class="routes provider-name">{{
                USER.userInfo.user.username
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="list-item" :ripple="false" @click="logout()">
            <v-list-item-content class="logout">
              <v-list-item-title class="routes logout"
                ><v-icon small color="white" class="mr-2">mdi-logout</v-icon
                >Выйти из профиля</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <div
            v-for="item in providerRoutes"
            :key="item.name"
            class="list-item"
            :class="item.path === $route.path && !drawer ? 'active-div' : ''"
          >
            <v-list-item
              :to="item.path"
              :class="item.path === $route.path && 'active-route'"
              :ripple="false"
            >
              <v-list-item-icon>
                <v-icon color="white">{{ item.meta.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content :ripple="false">
                <v-list-item-title class="routes">{{
                  item.name
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
          <div
            class="list-item"
            :class="'/how-work' === $route.path && 'active-div'"
          >
            <v-list-item
              to="/how-work"
              :ripple="false"
              :class="'/how-work' === $route.path && 'active-route'"
            >
              <v-list-item-content :ripple="false">
                <v-list-item-title class="routes special"
                  >Как работать?</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>
      <v-main id="content" height="100%" :class="darkActive">
        <v-container v-if="loading" fluid>
          <v-layout>
            <v-flex>
              <slot />
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      drawer: false,
      cities: [],
      city: 1,
      menu: false,
      sound: false,
      powers: [],
      theme: false,
      loading: true,
      routesGroup: {},
      isProvider: false,
      providerRoutes: [],
    };
  },
  computed: {
    ...mapGetters({
      CITY: "City/STATE",
      USER: "User/STATE",
      DEPARTMENT: "Department/STATE",
    }),
    mini() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    darkActive() {
      return this.theme ? "back_content-dark" : "back_content";
    },
  },
  watch: {
    async city(val) {
      this.loading = false;
      await this.setWatch(val);
      this.loading = true;
    },
  },
  async created() {
    this.loading = false;
    let theme = localStorage.getItem("theme");
    if (theme !== null) {
      theme = JSON.parse(theme);
      this.$dark.set(theme);
      this.theme = theme;
    }
    if (this.$cookie.get("token")) {
      await this.getUserInfo();
    }
    this.$root.user = this.USER.userId;
    if (!this.USER.userId) {
      return;
    }
    // для поставщиков другая отрисовка бокового меню
    if (this.USER.userInfo.user.roleId === 5) {
      this.isProvider = true;
      this.$root.isProvider = this.USER.userInfo.providerId;
    }
    this.powers = this.USER.userInfo.powers;
    await this.$power.set(this.powers);
    await this.allCity();
    await this.setCity();
    this.cities = this.CITY.allCity;
    this.getRoutes();
    this.loading = true;
  },
  methods: {
    ...mapActions({
      allCity: "City/GET_ALL_CITY",
      getUserInfo: "User/GET_USER_INFO",
      getDepartments: "Department/GET_DEPARTMENT",
      setDepUser: "User/SET_DEPARTMENT_USER",
    }),
    getRoutes() {
      // Все пути которые будут показаны в левом меню
      let routes;
      if (this.isProvider) {
        routes = this.$router.options.routes.filter(
          (route) => route.meta && route.meta.provider
        );
        // для поставщика в 1 массив и добавляем динамическим путям айдишник
        this.providerRoutes = routes.map((route) => {
          if (route.meta.dinamicRoute) {
            const arr = route.path.split(":");
            route.path = `${arr[0]}${this.USER.userInfo.providerId}`;
          }
          return route;
        });
      } else {
        routes = this.$router.options.routes.filter(
          (route) => route.meta && route.meta.group
        );
        // Распределяем их по группам
        routes.forEach((route) => {
          if (!this.routesGroup[route.meta.group]) {
            this.routesGroup[route.meta.group] = [];
          }
          this.routesGroup[route.meta.group].push(route);
        });
      }
    },
    async setWatch(val) {
      this.$root.city = val;
      localStorage.setItem("cityId", val);
      await this.getDepartments(this.city);
      await this.setDepUser(this.DEPARTMENT.cityDepartment);
    },
    async changeTheme() {
      this.loading = false;
      await this.$dark.set(this.theme);
      this.loading = true;
    },
    async setCity() {
      let localCity = localStorage.getItem("cityId");
      if (localCity !== null) {
        localCity = JSON.parse(localCity);
        this.$root.city = localCity;
        this.city = localCity;
      } else {
        this.$root.city = this.city;
      }
      await this.getDepartments(this.city);
      await this.setDepUser(this.DEPARTMENT.cityDepartment);
    },
    logout() {
      this.$cookie.delete("token");
      this.$cookie.delete("refreshToken");
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss">
.left-menu {
  overflow: visible !important;
  &_close {
    top: 40px;
    right: -16px;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0;
  }
}
.left-menu::-webkit-scrollbar {
  width: 0;
}
/* чтобы подменю не уходило вправо сильно */
.list-item {
  padding-left: 20px !important;
}
.routes {
  color: white;
  height: 40px !important;
  white-space: wrap !important;
  display: flex;
  align-items: center;
}
.special {
  color: #f3993e;
  font-weight: 700;
}
.logout {
  font-size: 13px !important;
  padding-top: 0 !important;
}
.provider-name {
  font-size: 24px !important;
  border-bottom: 1px white solid;
}
.active-div {
  position: relative;
  .v-list-item--link:before {
    background-color: white !important;
  }
  &::after {
    content: "";
    position: absolute;
    background-color: #5d4bd0;
    top: -30px;
    right: 0;
    height: 30px;
    width: 30px;
    border-bottom-right-radius: 16px;
    box-shadow: 0 16px 0 0 white;
    z-index: -1;
  }
  &::before {
    content: "";
    position: absolute;
    background-color: #5d4bd0;
    bottom: -30px;
    right: 0;
    height: 30px;
    width: 30px;
    border-top-right-radius: 16px;
    box-shadow: 0 -16px 0 0 white;
    z-index: -1;
  }
}

.active-route {
  z-index: 100;
  background-color: white !important;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  position: relative;
  .routes {
    color: #5d4bd0;
  }
}
.city {
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  &_select {
    color: black !important;
  }
}
.v-input__icon--append {
  color: black !important;
}
</style>
