import request from "../core/request";

export default {
  getYandexCourierPhone(orderId) {
    return request.execute(
      "get",
      `/admin/yandex/driver/info/get/phone/${orderId}`
    );
  },
  getYandexCourierCode(orderId) {
    return request.execute(
      "get",
      `/admin/yandex/driver/info/get/code/${orderId}`
    );
  },
};
